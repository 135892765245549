export const suppliersContactForm = () => {
    const form = $('#contact_form');

    form.on('submit', function (e) {
        e.preventDefault();

        $('.contact-form-messages').empty().show();

        $.ajax({
            url: '/contacts',
            type: 'POST',
            data: $(this).serialize(),
            success: function (response) {
                $('.contact-form-messages').html(`<div class="success-message">${response.message}</div>`);
                $('.contact-form-messages').html(messageContent).fadeOut(5000);
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'contact_form_submission',
                    'formLocation': 'supplier_contact'
                });
            },
            error: function (xhr) {
                const response = JSON.parse(xhr.responseText);
                let errorMessage = "";
                console.log(xhr.responseText)
                if (response.message) {
                    // Join multiple messages if they exist
                    errorMessage += response.message.join(", ");
                } else {
                    // Fallback error message
                    errorMessage += "Vormi saatmisel esines viga.";
                }
                $('.contact-form-messages').html(`<div class="error-message">${errorMessage}</div>`);
            }
        });
    });
};
