import { loader } from './googleLoader';

export const addressMap = (targetObj = 'address') => {
  const preloadedLat = $(`#${targetObj}_latitude`).attr('value');
  const preloadedLng = $(`#${targetObj}_longitude`).attr('value');

  const startLat = preloadedLat ? Number(preloadedLat) : 59.179282;
  const startLng = preloadedLng ? Number(preloadedLng) : 24.754781;

  let startPosition = { lat: startLat, lng: startLng };

  const shippingInput = $('.js-shipping-autocomplete');
  const billingInput = $('.js-billing-autocomplete');
  const hiddenForm = $('.js-hidden-form');

  let map;
  let marker;
  let geocoder;
  let shippingPlace;
  let shippingParsedAddress = {};
  let billingParsedAddress = {};
  let billingPlace;

  const autocompleteOptions = {
    fields: ['formatted_address', 'geometry', 'name', 'address_component'],
    strictBounds: false,
    componentRestrictions: { country: 'ee' },
    types: ['address'],
  };

  loader.load().then(() => {
    const $mapDiv = document.getElementById('map');
    map = new google.maps.Map($mapDiv, {
      zoom: 8,
      center: startPosition,
      disableDefaultUI: true,
      zoomControl: true,
    });

    marker = new google.maps.Marker({
      map: map,
      draggable: false,
      position: startPosition,
    });

    geocoder = new google.maps.Geocoder();

    autocompleteShipping();
    if ($('.js-billing-autocomplete')?.length > 0) autocompleteBilling();

    map.addListener('drag', () => marker.setPosition(map.getCenter()));
    map.addListener('zoom_changed', () => marker.setPosition(map.getCenter()));
    //update marker centered position
  });

  const getParam = (data, key) => {
    const item = data.find((item) => item.types.includes(key));
    return item ? item.long_name : '';
  };

  const parseAddress = (place, parsedAddress) => {
    parsedAddress.number = getParam(place.address_components, 'street_number');
    parsedAddress.street = getParam(place.address_components, 'route')
      ? getParam(place.address_components, 'route')
      : getParam(place.address_components, 'premise');
    parsedAddress.city = getParam(place.address_components, 'locality');
    parsedAddress.country = getParam(place.address_components, 'country');
    parsedAddress.zip = getParam(place.address_components, 'postal_code');
    parsedAddress.region = getParam(place.address_components, 'administrative_area_level_1');
  };

  const fillFields = () => {
    $(`#${targetObj}_street`).val(shippingParsedAddress.street);
    $(`#${targetObj}_house`).val(shippingParsedAddress.number);
    $(`#${targetObj}_city`).val(shippingParsedAddress.city);
    $(`#${targetObj}_zip`).val(shippingParsedAddress.zip);
    $(`#${targetObj}_region`).val(shippingParsedAddress.region);
    $(`#${targetObj}_country`).val(shippingParsedAddress.country);

    $(`#${targetObj}_longitude`).val(marker.position.lng());
    $(`#${targetObj}_latitude`).val(marker.position.lat());
  };

  const fillBillingFields = () => {
    $('#address_billing_street').val(billingParsedAddress.street);
    $('#address_billing_house').val(billingParsedAddress.number);
    $('#address_billing_city').val(billingParsedAddress.city);
    $('#address_billing_zip').val(billingParsedAddress.zip);
    $('#address_billing_region').val(billingParsedAddress.region);
    $('#address_billing_country').val(billingParsedAddress.country);
  };

  const autocompleteShipping = () => {
    const autocomplete = new google.maps.places.Autocomplete(shippingInput[0], autocompleteOptions);
    autocomplete.bindTo('bounds', map);

    map.addListener('dragend', () => {
      geocoder
        .geocode({ location: { lat: map.getCenter().lat(), lng: map.getCenter().lng() } })
        .then(
          (response) => {
            const address = response.results[0];
            const addressArray = response.results[0].address_components;

            const countryShortName = addressArray.find((item) => item.types.includes('country'));

            if (countryShortName.short_name === 'EE') {
              if (shippingParsedAddress.street !== getParam(address.address_components, 'route')) {
                parseAddress(address, shippingParsedAddress);
                fillFields();
                shippingInput.val(address.formatted_address);
              } else {
                parseAddress(address, shippingParsedAddress);
              }
            } else {
              alert('Vabandame, Roadly ei ole teie piirkonnas hetkel saadaval.');
              marker.setPosition(startPosition);
              map.setCenter(startPosition);
              map.setZoom(8);
              fillFields();
            }

            hiddenForm.removeClass('hidden-form');
          },
          (error) => console.error(error),
        );
    });

    autocomplete.addListener('place_changed', () => {
      shippingPlace = autocomplete.getPlace();

      if (shippingPlace.geometry.viewport) {
        map.fitBounds(shippingPlace.geometry.viewport);
      } else {
        map.setCenter(shippingPlace.geometry.location);
        map.setZoom(18);
      }
      marker.setPosition(shippingPlace.geometry.location);
      marker.setVisible(true);

      parseAddress(shippingPlace, shippingParsedAddress);
      fillFields();

      hiddenForm.removeClass('hidden-form');
    });
  };

  const autocompleteBilling = () => {
    const autocompleteBillingOptions = {
      fields: ['formatted_address', 'geometry', 'name', 'address_component'],
      strictBounds: false,
      types: ['address'],
    };

    const autocompleteBill = new google.maps.places.Autocomplete(
      billingInput[0],
      autocompleteBillingOptions,
    );

    autocompleteBill.addListener('place_changed', () => {
      billingPlace = autocompleteBill.getPlace();
      parseAddress(billingPlace, billingParsedAddress);
      fillBillingFields();
    });
  };

  if (shippingInput.val() !== '') {
    hiddenForm.removeClass('hidden-form');
  }
  shippingInput.on('change input', function () {
    !$(this).val().length ? hiddenForm.addClass('hidden-form') : null;
  });
};
