import { loader } from './googleLoader';

const baseURL = window.location.origin;

export const contactsMap = () => {
  const supplier = window.supplier;

  const mapDiv = document.getElementById('map');

  const point = { lat: supplier.latitude, lng: supplier.longitude };

  loader.load().then(() => {
    const map = new google.maps.Map(mapDiv, {
      zoom: 14,
      center: point,
    });

    const markerIcon = new google.maps.MarkerImage(
      'https://roadly.ee/images/pin-map.svg',
      null,
      new google.maps.Point(0, 0),
      new google.maps.Point(22, 44),
    );

    const marker = new google.maps.Marker({
      clickable: true,
      icon: markerIcon,
      position: { lat: supplier.latitude, lng: supplier.longitude },
      // icon: `https://roader.tk/images/pin-map.svg`,
      title: 'title',
      map: map,
    });
  });
};
