export const header = () => {
  const mainPage = $('.main-page');
  const header = $('.header');
  const headerMobile = $('.header-mobile');
  const logo = $('.header-logo');
  const button = header.find($('.btn-order'));
  const hamburger = headerMobile.find($('.hamburger'));
  const searchBlock = $('.js-search');
  const searchInput = $('.js-search-input');
  const btnShowSearch = $('.js-show-search');

  const headerGoWhite = () => {
    header.removeClass('header-transparent');
    headerMobile.removeClass('header-transparent');
    header.addClass('header-white');
    headerMobile.addClass('header-white');
    button.addClass('dark');
    //logo.attr('src', require('../images/logos/logo-dark.svg'))
    hamburger.removeClass('white');
  };

  const headerGoDark = () => {
    header.addClass('header-transparent');
    headerMobile.addClass('header-transparent');
    header.removeClass('header-white');
    headerMobile.removeClass('header-white');
    button.removeClass('dark');
    hamburger.addClass('white');
  };

  if (mainPage.length) {
    header.addClass('header-transparent');
    headerMobile.addClass('header-transparent');
    hamburger.addClass('white');
    button.removeClass('dark');
  }

  mainPage.length
    ? $(document).on('scroll', () =>
        $(document).scrollTop() > 0 ? headerGoWhite() : headerGoDark(),
      )
    : null;

  btnShowSearch.on('click', () => {
    if (searchInput.val() !== '') searchInput.toggleClass('show');
  });
};
