export const accordion = () => {
  const button = $('.js-accordion-btn');
  const icon = $('.js-accordion-icon');

  button.on('click', function () {
    const textClosed = $(this).siblings('.js-accordion-text-closed');
    const textOpened = $(this).siblings('.js-accordion-text-opened');

    icon.toggleClass('open');
    textClosed.toggleClass('show');
    textOpened.toggleClass('show');
    $(this).toggleClass('open');
    $(this).parents('.js-card-with-accordion').find('.accordion').toggleClass('open');
  });
};
