export const scrollMapPosition = () => {
  const mapBarContainer = $('.js-map-bar-container');
  const mapBar = $('.map-bar');
  const header = $('.js-header');

  $(window).on('scroll', () => {
    const scrollTop = $(window).scrollTop();
    const containerHeight = mapBarContainer.height();
    const windowHeight = $(window).height();
    const headerHeight = header.height();

    if (containerHeight - scrollTop < windowHeight - headerHeight) {
      mapBar.addClass('to-bottom');
    } else mapBar.removeClass('to-bottom');
  });
};
