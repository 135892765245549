import { suppliersShowHide } from "./suppliersShowHide";

export const calcPackingTypePrice = () => {
  const availableTypes = window.productData.productPackingTypes;
  const packingTypes = $('.js-packing-type');
  const currentPackage = $('.js-package');

  const setData = (type, name) => {
    const needToShow = $(`.js-${type}`);
    const needToHide = $('.js-supplier-price');

    currentPackage.html(name);
    needToHide.addClass('hidden');
    needToShow.removeClass('hidden');

    const checkEmpty = (el) => !$.trim(el.html());

    const isDisabled = needToShow.closest('.js-supplier').hasClass('disabled');

    needToShow.each(function () {
      const discount = $(this).find($('.js-discount'));
      const fullPrice = $(this).find($('.js-full-price'));
      const priceUnit = $(this).find($('.js-price-unit'));

      if (!isDisabled) {
        if (!checkEmpty(discount)) {
          discount.removeClass('hidden');
          fullPrice.addClass('old-price');
        }
      } else {
        discount.addClass('hidden');
        fullPrice.addClass('hidden');
        priceUnit.addClass('hidden');
      }
    });
    suppliersShowHide()
  };

  packingTypes.on('change', function () {
    setData($(this).val(), $(this).attr('data-name'));
  });

  packingTypes[0].setAttribute('checked', true);
  setData(availableTypes[0].id, availableTypes[0].name);
};
