import { suppliersFilter } from '../src/javascript/suppliersFilter';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

require('trix');
require('@rails/actiontext');

import 'slick-carousel';
import 'lightbox2';

const images = require.context('../src/images', true);
const imagePath = (name) => images(name, true);
window.imagePath = imagePath;

import '../src/stylesheets/application.scss';
import 'slick-carousel/slick/slick.css';
import 'lightbox2/src/css/lightbox.css';
import 'hamburgers/_sass/hamburgers/hamburgers.scss';
import '../src/stylesheets/components/hamburger_setting.scss';

import { resetScrollPos } from '../src/javascript/resetScrollPos';
import { notifications } from '../src/javascript/notifications';
import { confirmationModal } from '../src/javascript/confirmationModal';
import { header } from '../src/javascript/header';
import { hamburger } from '../src/javascript/hamburger';
import { titleAnimate } from '../src/javascript/titleAnimate';
import { categorySlider } from '../src/javascript/categoriesSlider';
import { partnersSlider } from '../src/javascript/partnersSlider';
import { offersSlider } from '../src/javascript/offersSlider';
import { accordion } from '../src/javascript/accordion';
import { mapToggleFullscreen } from '../src/javascript/mapToggleFullscreen';
import { suppliersMap } from '../src/javascript/suppliersMap';
import { contactsMap } from '../src/javascript/contactsMap';
import { infoModal } from '../src/javascript/infoModal';
import { dropdownToggle } from '../src/javascript/dropdown';
import { addressMap } from '../src/javascript/addressMap';
import { locationModal } from '../src/javascript/locationModal';
import { addressFormValidate } from '../src/javascript/addressFormValidate';
import { switchAddress } from '../src/javascript/switchAddress';
import { scrollMapPosition } from '../src/javascript/scrollMapPosition';
import { showMoreUnits } from '../src/javascript/showMoreUnits';
import { calcPackingTypePrice } from '../src/javascript/calcPackingTypePrice';
import { setGeoCookie } from '../src/javascript/setGeoCookie';
import { passwordShowHide } from '../src/javascript/passwordShowHide';
import { suppliersShowHide } from '../src/javascript/suppliersShowHide';
import { suppliersContactForm } from '../src/javascript/suppliersContactForm';

document.addEventListener('turbolinks:load', function () {
  resetScrollPos();
  header();
  hamburger();
  mapToggleFullscreen();
  infoModal();
  dropdownToggle();
  accordion();
  setGeoCookie();
  confirmationModal();
  notifications();
  locationModal();
  passwordShowHide();

  //main page scripts
  if (document.getElementsByClassName('main-page').length) {
    titleAnimate();
    categorySlider();
    partnersSlider();
    offersSlider();
  }
  //main page scripts

  //categories page scripts
  if (document.getElementsByClassName('categories-page').length) {
    // categoriesMap()
    // scrollMapPosition()
    showMoreUnits();
  }
  //categories page scripts

  //suppliers page scripts
  if (document.getElementsByClassName('suppliers-page').length) {
    suppliersMap();
    scrollMapPosition();
  }
  //suppliers page scripts

  //supplier contacts page scripts
  if (document.getElementsByClassName('supplier-contacts-page').length) {
    contactsMap();
    suppliersContactForm();
  }
  //supplier contacts page scripts

  //supplier products page scripts
  if (document.getElementsByClassName('supplier-products-page').length) {
    showMoreUnits();
  }
  //supplier products page scripts

  //product page scripts
  if (document.getElementsByClassName('product-page').length) {
    calcPackingTypePrice();
    suppliersFilter();
    suppliersShowHide();
  }
  //product page scripts

  //address page scripts
  if (document.getElementsByClassName('address-page').length) {
    addressMap();
    switchAddress();
    addressFormValidate();
  }
  //address page scripts

  document.addEventListener('keyup', (e) => {
    if (e.altKey && e.key == 'a') {
      window.location.replace(`/admin`);
    }
    if (e.altKey && e.key == 'h') {
      window.location.replace(`/`);
    }
  });
});
