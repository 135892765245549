export const passwordShowHide = () => {
  const showPass = $('.js-show-pass');

  showPass.on('click', function () {
    const input = $(this).parent().find('input');

    $(this).toggleClass('show');
    input.toggleClass('password-input');
  });
};
