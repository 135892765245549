export const dropdownToggle = () => {
  const dropdown = $('.js-dropdown');
  const dropdownListItem = $('.js-list-item');
  const dropdownSelected = $('.js-dropdown-selected');
  const input = dropdown.find('.js-input');

  dropdown.on('click', function () {
    $(this).toggleClass('open');
  });

  dropdownListItem.on('click', function () {
    $(this).parent(dropdown).toggleClass('open');
    $(this).closest('.js-dropdown').find('.js-dropdown-selected').html($(this).text());
    input.val($(this).attr('data-value'));

    // dropdown.submit()
    window.location.href = $(this).attr('data-link');
  });

  const onClickOutside = (e) => {
    if (!dropdown.is(e.target) && dropdown.has(e.target).length === 0) {
      dropdown.removeClass('open');
    }
  };

  $('body').on('click', (e) => onClickOutside(e));
};
