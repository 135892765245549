import t from 'js-cookie/dist/js.cookie.min.mjs';
import { loader } from './googleLoader';

const baseURL = window.location.origin;

const contentString = (point) => `
    <div id="content" class="card map-card flex col justify-content-between">
        <div class="flex justify-content-between">
            <div class="flex col">
                <div class="body-text text-green wrap-break-word m-b-6">${point.categories_urls.map(
                  (category) => `<a href='${category.path}'> ${category.name}</a>`,
                )}</div>
                <a href="${point.path}"" class="card-title m-b-8" title="${point.name}">${
                  point.name
                }</a> 
              </div>
            </div>
        <div class="flex align-items-end">
            <span class="body-text-3 text-secondary with-icon distance">${
              point.distance
            } km to you</span>
        </div>

        <div class="flex align-items-end">
            <span class="body-text-3 text-secondary with-icon location">${point.address}</span>
        </div>
    </div>
`;

const ZoomControl = (controlDiv, map) => {
  controlDiv.style.right = '0px';
  controlDiv.style.paddingRight = '32px';
  controlDiv.style.paddingTop = '32px';
  controlDiv.style.display = 'flex';
  controlDiv.style.justifyContent = 'flex-end';

  // Set CSS for the control wrapper
  const controlWrapper = document.createElement('div');
  controlWrapper.style.cursor = 'pointer';
  controlWrapper.style.width = '40px';
  controlWrapper.style.height = '86px';
  controlDiv.appendChild(controlWrapper);

  const zoomInButton = document.createElement('div');
  zoomInButton.style.marginBottom = '6px';
  zoomInButton.style.width = '40px';
  zoomInButton.style.height = '40px';
  zoomInButton.style.backgroundImage = `url(https://roadly.ee/images/zoomin.svg)`;
  controlWrapper.appendChild(zoomInButton);

  const zoomOutButton = document.createElement('div');
  zoomOutButton.style.width = '40px';
  zoomOutButton.style.height = '40px';
  zoomOutButton.style.backgroundImage = `url(https://roadly.ee/images/zoomout.svg)`;
  controlWrapper.appendChild(zoomOutButton);

  google.maps.event.addDomListener(zoomInButton, 'click', () => map.setZoom(map.getZoom() + 1));
  google.maps.event.addDomListener(zoomOutButton, 'click', () => map.setZoom(map.getZoom() - 1));
};

export const suppliersMap = () => {
  const suppliers = window.suppliers;

  const mapDiv = document.getElementById('map');

  const isDesktopWidth = $(window).width() >= '998';

  const region = window.region;

  const regionsCenters = {
    'Harju maakond': { lat: 59.340740021726475, lng: 25.312153517253243 },
    'Lääne maakond': { lat: 58.97164465850757, lng: 23.874181614621538 },
    'Ida-Viru maakond': { lat: 59.260277534390234, lng: 27.403376806846953 },
    'Rapla maakond': { lat: 58.98907471232626, lng: 24.655495314877616 },
    'Pärnu maakond': { lat: 58.5282107922225, lng: 24.402921133226645 },
    'Järva maakond': { lat: 58.88704219501379, lng: 25.49950252821211 },
    'Hiiu maakond': { lat: 58.92320021142427, lng: 22.592034990665688 },
    'Saare maakond': { lat: 58.47920926472212, lng: 22.613084882123093 },
    'Jõgeva maakond': { lat: 58.747848835854526, lng: 26.360506821061332 },
    'Tartu maakond': { lat: 58.40337595607718, lng: 26.802541144288707 },
    'Viljandi maakond': { lat: 58.31161449074227, lng: 25.567593904882646 },
    'Valga maakond': { lat: 57.91242129699153, lng: 26.164474208086173 },
    'Põlva maakond': { lat: 58.113994003353135, lng: 27.211027816945915 },
    'Võru maakond': { lat: 57.733909491926354, lng: 27.137521802783876 },
  };

  loader.load().then(() => {
    const map = new google.maps.Map(mapDiv, {
      zoom: 9,
      center: regionsCenters[region],

      fullscreenControl: false,
      disableDefaultUI: true,
      zoomControl: false,
    });

    const zoomControlDiv = document.createElement('div');
    const zoomControl = new ZoomControl(zoomControlDiv, map);
    zoomControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(zoomControlDiv);

    let activeInfoWindow = null;
    suppliers.map((supplier) => {
      const card = $('.js-supplier-card');
      const name = supplier.name;

      const marker = new google.maps.Marker({
        clickable: true,
        position: { lat: supplier.latitude, lng: supplier.longitude },
        title: 'title',
        map: map,
      });

      const markerIcon = new google.maps.MarkerImage(
        'https://roadly.ee/images/pin-map.svg',
        null,
        new google.maps.Point(0, 0),
        new google.maps.Point(22, 44),
      );

      const alterMarkerIcon = new google.maps.MarkerImage(
        'https://roadly.ee/images/pin-hovered.svg',
        null,
        new google.maps.Point(0, 0),
        new google.maps.Point(19, 44),
      );

      marker.setIcon(markerIcon);

      let infowindow = new google.maps.InfoWindow({
        content: contentString(supplier),
      });

      const markerAutoClose = () => {
        if (activeInfoWindow) {
          activeInfoWindow.close();
        }
        infowindow.open(map, marker);
        activeInfoWindow = infowindow;
      };

      const scrollToCard = () => {
        card.removeClass('active');
        $(`[data-name='${name}']`).addClass('active');
        $('html, body').animate(
          {
            scrollTop: $(`[data-name='${name}']`).offset().top - 330,
          },
          {
            duration: 400,
            easing: 'swing',
          },
        );
      };

      marker.addListener('click', () => {
        markerAutoClose();

        isDesktopWidth && scrollToCard();
      });

      if (isDesktopWidth) {
        google.maps.event.addListener(infowindow, 'closeclick', function () {
          card.removeClass('active');
        });

        card.on('mouseover', function () {
          if ($(this).attr('data-name') === name) {
            marker.setIcon(alterMarkerIcon);
          }
        });

        card.on('mouseleave', function () {
          if ($(this).attr('data-name') === name) {
            marker.setIcon(markerIcon);
          }
        });
      }
    });
  });
};
