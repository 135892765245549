export const switchAddress = () => {
  const radio = $('input[type=radio][name="address[billing_like_shipping]"]');
  const block = $('.different-address');

  if (radio[1].checked) block.addClass('show');

  radio.change(function () {
    if (this.value == 'false') {
      block.addClass('show');
    } else {
      block.removeClass('show');
    }
  });
};
