import { loader } from './googleLoader';
import Cookies from 'js-cookie';

export const locationModal = () => {
  const body = $('body');
  const modal = $('.js-location-modal');
  const showModal = $('.js-show-location');
  const closeModal = $('.js-close-location');
  const hamburger = $('.hamburger');
  const navbar = $('.navbar-mobile');
  const input = $('.js-autocomplete');

  showModal.on('click', (event) => {
    event.preventDefault();
    modal.toggleClass('open');
    !body.hasClass('no-scroll') && body.addClass('no-scroll');

    hamburger.removeClass('is-active');
    navbar.removeClass('active');

    const inputVal = decodeURI(Cookies.get('autocomplete_string'));
    if (inputVal && inputVal != undefined && inputVal != 'undefined') {
      input.val(inputVal || '');
    } else {
      input.val('');
    }
  });

  const close = () => {
    modal.toggleClass('open');
    body.removeClass('no-scroll');
  };

  closeModal.on('click', (event) => {
    event.target === event.currentTarget ? close() : null;
  });

  const getParam = (data, key) => {
    const item = data.find((item) => item.types.includes(key));
    return item ? item.long_name : '';
  };

  loader.load().then(() => {
    const autocompleteOptions = {
      fields: ['formatted_address', 'geometry', 'name', 'address_component'],
      strictBounds: false,
      componentRestrictions: { country: 'ee' },
      types: ['address'],
    };
    const autocomplete = new google.maps.places.Autocomplete(input[0], autocompleteOptions);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const autocompleteString = place.formatted_address;

      window.coordsString = `${lat},${lng}`;
      window.city = getParam(place.address_components, 'locality');
      window.region = getParam(place.address_components, 'administrative_area_level_1');
      window.autocompleteString = autocompleteString;
    });
  });

  $('.use-address').click(function () {
    if (!window.coordsString || window.coordsString == 'undefined') return close();

    Cookies.set('user_coords_prefered', coordsString);
    Cookies.set('city_name_prefered', window.city || window.region);
    Cookies.set('region_name_prefered', window.region);
    Cookies.set('autocomplete_string', window.autocompleteString);

    document.location.reload();

    //close()
  });
};
