import $ from 'jquery';

export const offersSlider = () => {
  const slider = $('.offers-slider');
  slider.slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    autoplay: true,
  });

  $('#offers-arrow-prev').click(() => slider.slick('slickPrev'));
  $('#offers-arrow-next').click(() => slider.slick('slickNext'));

  $(document).on('turbolinks:before-cache', function () {
    slider.slick('unslick');
  });
};
