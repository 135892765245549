export const suppliersShowHide = () => {
  const suppliersBlock = $('.js-show-hide-suppliers');
  const suppliers = suppliersBlock.find('.js-supplier');
  const btn = $('.js-see-more-suppliers');
  const seeMore = $('.js-text-more');
  const seeLess = $('.js-text-less');

  const hideSuppliers = () => {
    const type = window.productData.packingType;
    const needToShow = $(`.js-supplier-${type}`);

    suppliers.addClass('hidden')
    needToShow.each((i, toShow) => {
      if (i < 2) {
        $(toShow).removeClass('hidden');
      }
    });
  };

  const showSuppliers = () => {
    const type = window.productData.packingType;
    const needToShow = $(`.js-supplier-${type}`);

    suppliers.addClass('hidden');
    needToShow.removeClass('hidden');
  };

  if (suppliers.length > 2) {
    btn.removeClass('hidden');
    hideSuppliers();
  }

  btn.off('click');
  btn.on('click', (event) => {
    event.preventDefault(event);

    btn.hasClass('active') ? hideSuppliers() : showSuppliers();

    btn.toggleClass('active');
    seeMore.toggleClass('hidden');
    seeLess.toggleClass('hidden');
  });
};
