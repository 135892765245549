export const mapToggleFullscreen = () => {
  const body = $('body');
  const map = $('.map-bar');
  const button = $('.js-toggle-map');

  button.on('click', () => {
    body.toggleClass('no-scroll');
    button.toggleClass('back');
    map.toggleClass('fullscreen');
  });
};
