export const hamburger = () => {
  const hamburger = $('.hamburger');
  const search = $('.js-search-btn');
  const searchInput = $('.js-search-input');
  const navbar = $('.navbar-mobile');
  const body = $('body');

  let scrollDistance = 0;

  const action = () => {
    const modal = $('.js-location-modal');

    if (modal.hasClass('open')) {
      modal.removeClass('open');
      body.removeClass('no-scroll');
    }

    if (!body.hasClass('no-scroll')) {
      scrollDistance = window.pageYOffset;

      hamburger.addClass('is-active');
      navbar.addClass('active');
      body.addClass('no-scroll');
    } else {
      hamburger.removeClass('is-active');
      navbar.removeClass('active');
      body.removeClass('no-scroll');

      $(window).scrollTop(scrollDistance);
    }
  };

  hamburger.on('click', () => action());
  search.on('click', () => {
    action();
    searchInput.focus();
  });
};
