import Cookies from 'js-cookie';
import { loader } from './googleLoader';

export const setGeoCookie = () => {
  const existingCoords = Cookies.get('user_coords');

  const getParam = (data, key) => {
    const item = data.find((item) => item.types.includes(key));
    return item ? item.long_name : '';
  };

  if (existingCoords) return;

  navigator.geolocation.getCurrentPosition(
    async (position) => {
      const coords = `${position.coords.latitude},${position.coords.longitude}`;
      Cookies.set('user_coords', coords);

      const result = await getGeocoderResult(position.coords.latitude, position.coords.longitude);

      if (result) {
        Cookies.set('autocomplete_string', result.formatted_address);
        Cookies.set('city_name', getParam(result.address_components, 'locality'));
        Cookies.set(
          'region_name',
          getParam(result.address_components, 'administrative_area_level_1'),
        );
      } else {
        Cookies.set('city_name', 'Rapla');
      }

      document.location.reload();
    },
    () => {
      Cookies.set('user_coords', '59.00025671809766,24.804676006175338');
      Cookies.set('city_name', 'Rapla');

      document.location.reload();
    },
  );
  // document.location.reload();  to let controller use this cookie
};

const getGeocoderResult = async (latitude, longitude) => {
  await loader.load();

  const geocoder = new google.maps.Geocoder();
  const latlng = new google.maps.LatLng(latitude, longitude);

  return await new Promise((resolve, reject) => {
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[1]) {
        resolve(results[1]);
      } else {
        reject();
      }
    });
  });
};
