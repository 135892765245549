export const notifications = () => {
  const template = (msg) =>
    `<div class="flex align-items-center notification ${msg.status}">${msg.text}</div>`;
  const setBtn = $('.js-set-notify');

  const notify = (msg) => {
    localStorage.removeItem('notify');

    $('body').append(template(msg));

    const elem = $(`.${msg.status}`);
    setTimeout(() => elem.addClass('show'), 1000);
    setTimeout(() => elem.remove(), 6000);
  };

  const getFromStorage = () => {
    const msg = JSON.parse(localStorage.getItem('notify'));
    msg && notify(msg);
  };

  const showFromExistingInDom = () => {
    const blocks = $('.js-notify');

    if (blocks.hasClass('error')) {
      localStorage.removeItem('notify');
    }

    if (blocks.length) {
      blocks.each(function () {
        if ($(this)[0].innerText.length) {
          $(this).addClass('show');
          setTimeout(() => $(this).removeClass('show'), 5000);
        }
      });
    }
  };

  const setNotify = (text, status) => {
    localStorage.setItem(
      'notify',
      JSON.stringify({
        text: text,
        status: status,
      }),
    );
  };

  setBtn.on('click', function () {
    setNotify($(this).attr('data-text'), $(this).attr('data-status'));
  });

  showFromExistingInDom();
  getFromStorage();
};
