export const suppliersFilter = () => {
  const availableTypes = window.productData.productPackingTypes;
  const packingTypes = $('.js-packing-type');
  const allSuppliers = $('.js-supplier');
  const toggleBlock = $('.js-toggle-block');
  const seeMore = $('.js-see-more-suppliers');

  const show = (type) => {
    const needToShow = $(`.js-supplier-${type}`);

    if (needToShow.length) {
      toggleBlock.removeClass('hidden');
      needToShow.removeClass('hidden');

      needToShow.first().addClass('best-offer');
    } else {
      toggleBlock.addClass('hidden');
    }

    if (needToShow.length <= 2) {
      seeMore.addClass('hidden');
    } else {
      seeMore.removeClass('hidden');
    }
  };

  const hide = () => allSuppliers.addClass('hidden');

  const change = (type) => {
    hide();
    show(type);
  };

  packingTypes.on('change', function () {
    change($(this).val());
    window.productData.packingType = $(this).val();
    const href = $('.order-now-btn').attr('href');
    if (href) {
      const url = new URL(href);
      url.searchParams.set('packingType', window.productData.packingType);
      $('.order-now-btn').attr('href', url)
    }
  });

  allSuppliers.on('click', function () {
    const id = $(this).data('id')
    const href = $('.order-now-btn').attr('href');
    if (href) {
      const url = new URL(href);
      url.searchParams.set('supplier', id);
      $('.order-now-btn').attr('href', url)
    }
  });

  change(availableTypes[0].id);
  window.productData.packingType = availableTypes[0].id;
};
