export const addressFormValidate = () => {
  const submitBtn = $('.js-submit-address');

  const radio = $('.js-radio');

  const autocompleteInput = $('.js-shipping-autocomplete');
  const billingAutocompleteInput = $('.js-billing-autocomplete');

  const inputs = [
    $('.js-street-input'),
    $('.js-number-input'),
    $('.js-city-input'),
    $('.js-zip-input'),
    $('.js-region-input'),
    $('.js-country-input'),
  ];

  const billingInputs = [
    $('.js-billing-street-input'),
    $('.js-billing-number-input'),
    $('.js-billing-city-input'),
    $('.js-billing-zip-input'),
    $('.js-billing-region-input'),
    $('.js-billing-country-input'),
  ];

  const checkValid = () => {
    const isBillingLikeShipping =
      $('input[name="address[billing_like_shipping]"]:checked').val() === 'true';

    const isShippingComplete = !inputs.find((input) => input.val() === '');
    const isBillingComplete = !billingInputs.find((input) => input.val() === '');

    if (isBillingLikeShipping) {
      if (isShippingComplete) {
        submitBtn.attr('disabled', false);
      } else {
        submitBtn.attr('disabled', true);
      }
    } else {
      if (isShippingComplete && isBillingComplete) {
        submitBtn.attr('disabled', false);
      } else {
        submitBtn.attr('disabled', true);
      }
    }
  };

  inputs.forEach((input) => {
    input.on('change change keyup input paste', () => checkValid());
  });

  billingInputs.forEach((input) => {
    input.on('change change keyup input paste', () => checkValid());
  });

  radio.on('change', () => checkValid());

  autocompleteInput.on('change keyup blur input', () => {
    checkValid();
  });

  billingAutocompleteInput.on('change keyup blur input', () => {
    checkValid();
  });

  $(window).on('mouseup scroll', () => {
    checkValid();
  });
};
