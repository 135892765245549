export const infoModal = () => {
  const modal = $('.js-info-modal');
  const showModal = $('.js-show-info');
  const closeModal = $('.js-close-info');

  showModal.on('click', (event) => {
    modal.toggleClass('open');
    $('body').toggleClass('no-scroll');
  });

  const close = () => {
    modal.toggleClass('open');
    $('body').toggleClass('no-scroll');
  };

  closeModal.on('click', (event) => {
    event.target === event.currentTarget ? close() : null;
  });
};
