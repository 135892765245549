import $ from 'jquery';

export const partnersSlider = () => {
  const slider = $('.partners-slider');
  slider.slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  });

  $('#partners-arrow-prev').click(() => slider.slick('slickPrev'));
  $('#partners-arrow-next').click(() => slider.slick('slickNext'));

  $(document).on('turbolinks:before-cache', function () {
    slider.slick('unslick');
  });
};
