export const showMoreUnits = () => {
  const product = $('.js-product');
  const btnSeeMore = $('.js-see-more');

  const itemsLength = Number(btnSeeMore.attr('data-length'));

  product.each(function () {
    let units = $(this).find('.js-product-item');

    units.each(function (index) {
      index >= itemsLength ? $(this).addClass('hidden') : null;
    });
  });

  btnSeeMore.on('click', function () {
    const textMore = $(this).find('.js-text-more');
    const textLess = $(this).find('.js-text-less');

    let units = $(this).parents('.js-product').find('.js-product-item');

    units.each(function (index) {
      index >= itemsLength ? $(this).toggleClass('hidden') : null;
    });

    $(this).toggleClass('active');
    textMore.toggleClass('hidden');
    textLess.toggleClass('hidden');
  });
};
