import Typed from 'typed.js';

export const titleAnimate = () => {
  const typed = new Typed('#typed', {
    strings: ['Liiva', 'Killustiku', 'Mulla', 'Multši', 'Paekivi'],

    stringsElement: null,
    typeSpeed: 160,
    startDelay: 600,
    backSpeed: 80,
    backDelay: 1000,
    loop: true,
  });
};
